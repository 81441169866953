// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  origin: 'https://api.diffusionco.com',
  wcEndpoint: '/wp-json/wc/v2',
  woocommerce: {
    consumer_key:  'ck_f13cabdfed39ce7ae1482d6fbbb1f751f86a0c82',
    consumer_secret: 'cs_f9f7e1259ab614fadb137f0498dd670be60c646c',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
